.file-upload {
  width: 100px;
  height: 100px;
  border: 2px solid #dee2e6;
  border-radius: 8px;

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-align: center;
  font-size: 12px;

  .label-icon {
    font-size: 32px;
    cursor: pointer;
  }

  .label-text {
    margin-top: 8px;
    cursor: pointer;
  }

  .file-input {
    display: none;
  }

  .progress-label {
    margin-top: 8px;
    padding: 0 4px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .preview-wrapper {
    max-width: 100%;
    max-height: 100%
  }

  .pdf-icon {
    max-height: 60px;
  }

  .image {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    object-fit: contain;
  }

  .file-name {
    padding: 0 4px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}