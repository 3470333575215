.contacts-ctn {
  position: absolute;
  top: 10px;
  right: 90px;

  a {
    color: var(--icons-color);
    font-size: 24px;
    margin-left: 15px;
  }
}

@media screen and (max-width: 576px) {

  .contacts-ctn{
    right: 30px;
  }
}