.AttendInterview-form-ctn {
  background: var(--content-background-color);
  min-height: 100vh;
  position: relative;

  .middle-ctn {
    max-width: 600px;
    min-height: 680px;
    padding: 40px 30px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    color: var(--text-color);

    .contacts-ctn {
      top: 190px;
      right: 80px;
    }

    @media screen and (max-width: 576px) {

      .contacts-ctn{
        right: 30px;
      }
    }

    .form-title {
      text-align: center;
    }

    .form-description {
      white-space: pre-wrap;
    }

    hr {
      margin: 30px 0;
    }

    .questions-ctn {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-grow: 1;

      .questions-list-controls {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .carousel {

        .carousel-inner {
          padding: 0 2px;
        }
      }
    }

    .form-check-input,
    .form-select,
    .form-control {
      background-color: var(--content-background-color);
      color: var(--text-color);

      &:checked {
        background-color: var(--icons-color);
        border-color: var(--icons-color);
      }

      &:focus {
        border-color: var(--icons-color);
        box-shadow: none;
      }
    }

    .form-select,
    .form-control {
      &:focus {
        outline: 1px solid var(--icons-color);
      }
    }

    .file-upload {
      border-color: var(--icons-color);
    }

    svg {
      color: var(--icons-color);
    }

    .btn {
      background: var(--buttons-color);
      border-color:var(--buttons-color);
      color: var(--buttons-text-color);
    }

    @media screen and (max-width: 1080px) {
      
      .form-title {
        padding-top: 80px;
      }
    }
  }
}