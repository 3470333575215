.record-btn {
  background-color: transparent;
  border: none;
  padding: 5px;
  margin: 0;
  font-size: 26px;
  width: fit-content;

  display: flex;
  align-items: center;

  &:hover {
    background-color: transparent;
  }
}