$width: 1024px;
$logoSize: 180px;
$padding: 80px;

.custom-bg {
  height: ($width / 6);
  width: 100%;
  background: var(--banner-color);
  background-image: var(--banner-image);
  background-size: cover;
  background-position: center;

  .logo-ctn {
    background: var(--background-color);;
    border-radius: 12px;
    position: absolute;
    top: ($logoSize / 2);
    left: $padding;
    width: $logoSize;
    height: $logoSize;
    border: 10px solid var(--background-color);;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  @media screen and (max-width: 576px) {

    .logo-ctn {
      left: 30px;
    }
  }
}
