.InterviewStatsPage {
  padding: 0 15px;

  td {
    vertical-align: middle;

    .badge {
      font-size: 14px;
    }
  }

  .horizontal-filter {
    display: inline-flex;
    flex-direction: row;
    align-items: center;

    .label {
      margin-right: 20px;
    }
  }

  .toolbox-cell {
    font-size: 24px;
    line-height: 1em;
    text-align: center;

    a {
      color: inherit;
    }

    svg {
      margin-right: 10px;
      position: relative;
      top: -1px;
      cursor: pointer;

      &:hover {
        color: #0d6efd;
      }
    }
  }
}