.assignment-toolbar-ctn {
  margin: 0 15px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .SelectInput {
    display: flex;
    align-items: center;
    margin-bottom: 0;

    .form-label {
      display: inline-flex;
      width: 90px;
      margin: 0 15px;
    }
  }
}

//.reactour__popover {
//  transform: none !important;
//}