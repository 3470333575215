$width: 240px;

.login-ctn {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .width {
    width: $width;
  }

  .reset-sent {
    //text-align: center;
  }

  .logo {
    width: $width;
    margin-bottom: 40px;
  }

  hr {
    margin: 0;
    width: $width;
  }

  .google-btn {
    width: $width;
    color: #002956;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;

    svg {
      margin-right: 15px;
    }
  }

  .login-form {
    width: $width;

    .signup-link {
      display: flex;
      font-size: 14px;
      justify-content: center;

      span {
        margin-right: 10px;
      }

      .forgot-password {
        padding: 0;
        font-size: 14px;
      }
    }

    .login-btn {
      width: $width;
    }

    .password-ctn {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .forgot-password {
        font-size: 14px;
        padding-right: 0;
      }
    }
  }

  .recover-pswd-form {
    width: $width;

    .recover-pswd-btn {
      width: $width;
    }
  }

  .back-to-signin-ctn {
    display: flex;
    justify-content: center;

    .back-to-signin {
      font-size: 14px;
      padding-left: 0;
      padding-right: 0;
    }
  }
}