$width: 1024px;
$logoSize: 180px;
$padding: 80px;

.CompanyProfileLayout {
  position: fixed;
  top: 0;
  left: 0;
  background: var(--background-color);
  z-index: 6;
  width: 100vw;
  max-height: 100vh;
  overflow-y: auto;
  display: flex;
  justify-content: center;

  .scroll-area {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;

    .content-area {
      max-width: $width;
      min-height: 100vh;
      width: 100%;
      background: var(--content-background-color);
      position: relative;

      .title-area {
        width: 100%;
        position: relative;
        padding: ($logoSize / 2 + 10px) ($padding + 10px) 30px;
        color: var(--text-color);

        .title {
          font-size: 40px;
          font-weight: 500;
        }

        .tagline {
          font-size: 20px;
        }

        .description {
          font-size: 16px;
          white-space: pre-wrap;
        }
      }

      hr {
        color: var(--icons-color);
      }

      .positions-ctn {
        color: var(--text-color);
        font-weight: 500;
        padding: 20px ($padding + 10px);

        .positions-title {
          text-align: center;
          font-size: 24px;
          margin-bottom: 20px;
        }

        .no-vacancies-warning {
          text-align: center;
        }

        .accordion-item,
        .accordion-button {
          background: var(--content-background-color);
          color: var(--text-color);
        }

        .accordion-item {
          border-color: #ccc;
        }

        .accordion-button {
          font-weight: 500;

          &:focus {
            box-shadow: none;
          }

          &:not(.collapsed) {
            box-shadow: none;
          }
        }

        .accordion-body {
          display: flex;
          flex-direction: column;
          gap: 20px;
          white-space: pre-wrap;
          font-weight: 400;
        }

        .apply-button {
          width: fit-content;
          background: var(--buttons-color);
          border-color: var(--buttons-color);
          color: var(--buttons-text-color);
        }      
      }

      @media screen and (max-width: 576px) {

        .title-area {
          padding: ($logoSize / 2 + 10px) 30px 30px;
        }

        .positions-ctn {
          padding: 30px;
        }

      }  
    }
  }

}