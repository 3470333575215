.form-control {

  &:focus {
    border-color: transparent;
    box-shadow: 0 0 0 2px rgba(13, 110, 253, 0.9);
  }
}

.reactour__popover {
  color: black !important;
}
