$primary-width: 500px;

.content-tab {
  .url {
    display: flex;
    flex-direction: column;

    .url-input-row {
      display: flex;
      position: relative;
      flex-direction: row;
      align-items: center;
      width: $primary-width;
      gap: 5px;

      .TextInput {
        flex-grow: 1;
      }

      svg {
        &:hover {
          color: #0d6efd;
        }
      }
    }
  }

  .company-description,
  .name,
  .tagline {
    width: $primary-width;
  }
}