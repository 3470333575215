.CreateInterview {
  display: flex;
  flex-direction: column;
  min-height: 500px;

  // .select-row {
  //   display: flex;
  //   justify-content: space-between;
  //   width: 300px;
  // }

  // .helper-ctn {
  //   position: relative;
  //   top: 4px;
  // }

  .back-button {
    font-size: 28px;
    width: fit-content;
    border: none;
    background-color: transparent;
    padding: 0;
  }
}