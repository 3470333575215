$buttonSize: 36px;

.picker-ctn {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  .button {
    width: $buttonSize;
    height: $buttonSize;
    border-radius: 8px;
    cursor: pointer;
    border: 1px solid #dee2e6;
  }

  .sketch-picker {
    margin: ($buttonSize + 2px) 0 0 0;
  }

}