.InterviewAnswersPage {
  padding: 0 15px;

  .top-bar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    align-items: flex-start;

    .candidate-details {
      font-size: 20px;

      span {
        font-size: 16px;
        margin-right: 10px;
      }

      svg {
        cursor: pointer;
        margin: 0 10px;
        font-size: 20px;

        &:hover {
          color: #0d6efd;
        }
      }
    }
  }
}