.interviews-page-ctn {

  .tour-btn {
    position: absolute;
    top: 0;
    left: 0;
    margin: auto;
    bottom: 0;
    right: 0;
    max-width: 200px;
    max-height: 40px;
  }
}