.ps-sidebar-root {
  border-color: #444 !important;

  .ps-sidebar-container {
    background-color: transparent !important;

    .ps-submenu-content {
      background-color: transparent;

    }

    .ps-menu-button {

      &:hover {
        background-color: #181a1e !important;
      }

    }
  }
}
