.modal {

  &.show {
    .modal-dialog {
      transform: translate(0, -50px);
    }
  }

  .modal-content {
    background-color: var(--bg-color, #212529);  
  }

  .modal-body {
    color: var(--text-color, #dee2e6);
  }

  .footer {
    display: flex;
    flex-direction: column;
    align-items: end;

    .buttons {
      display: flex;
      gap: 8px;
    }
  }
}