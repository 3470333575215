$size: 40px;

.header-ctn {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;

  .logo-ctn {
    width: 200px;
    height: $size;
    cursor: pointer;
  }

  .trial-ctn {
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: end;
    padding: 0 40px;
  }

  .UserAvatar-ctn {
    height: $size;
    width: $size;
  }
}

.header-popover {

  .popover-body {
    padding: 0;

    .list-group-item {
      cursor: pointer;

      &:last-child {
        border-radius: 8px;
      }

      &:hover {
        background-color: #181a1e;
      }
    }
  }
}