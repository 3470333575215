.my-company-ctn {

  .preview-btn {
    position: fixed;
    bottom: 20px;
    right: 0;
    left: 34px;
    width: 180px;
    z-index: 9;
  }
}