$sidebarWidth: 250px;
$headerHeight: 60px;
$padding: 15px;

.MainPage {
  width: 100vw;
  height: 100vh;
  position: relative;

  .top {
    width: 100%;
    height: $headerHeight;
    border-bottom: 1px solid #444;
    position: absolute;
    top: 0;
    left: 0;
  }

  .left {
    height: calc(100% - $headerHeight);
    width: $sidebarWidth;
    position: absolute;
    top: $headerHeight;
    left: 0;
    padding-top: 10px;

    .ps-sidebar-root {
      height: 100%;
    }
  }

  .right {
    min-height: calc(100% - $headerHeight);
    width: calc(100vw - $sidebarWidth);
    padding: $padding;
    position: absolute;
    top: $headerHeight;
    left: $sidebarWidth;
  }
}