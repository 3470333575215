.CardMenu {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  border: 1px solid transparent;

  opacity: 0;
  transition: opacity 0.2s ease-out;

  &:hover {
    opacity: 1;
    transition: opacity 0.2s ease-in;
    cursor: pointer;
  }

  .left-action, .right-action {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 36px;
    background: #22262af2;
    border-radius: 6px;
  }

  .left-action {
    width: 70%;

    &:hover {
      color: #0d6efd;
    }
  }

  .right-action {
    width: 30%;
    border-left: 1px solid #333;

    &:hover {
      color: #961610;
    }
  }

  .full-width {
    width:100%
  }
}
