.video-input-ctn {
  max-width: 640px;
  display: flex;
  flex-direction: column;

  .video-frame {
    width: 100%;
    position: relative;

    video {
      height: 100%;
      width: 100%;
    }

    .progress-label {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #181a1edd;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }



  .controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .btn {
      display: inline-block;
      margin-right: 15px;
    }

    .delete-video-btn {
      background-color: #dc3545;
      border-color: #dc3545;
      color: #fff;
    }
  }
}