.tooltip {
  $bgColor: black;

  .tooltip-inner {
    background: $bgColor;
    text-align: start;

    span {
      color: #eee !important;
    }
  }

  .tooltip-arrow {
    &:before {
      border-top-color: $bgColor;
    }
  }
}
