.ChangeStatusButtons {

  .icon {
    font-size: 24px;
    line-height: 1em;

    &.active {
      color: #0d6efd;
    }

    svg {
      margin-right: 10px;
      position: relative;
      top: -1px;
      cursor: pointer;

      &:hover {
        color: #0d6efd;
      }
    }
  }
}
