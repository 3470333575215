.QuestionPreview {
  display: flex;
  flex-direction: column;

  .question-ctn {

    .form-label {
      font-size: 20px;
    }

    .num {
      text-align: center;
    }

  }

  .answer-ctn {

  }
}