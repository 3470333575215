.question-list {
  padding-bottom: 1rem;

  hr {
    margin: 2rem 0;
  }

  .create-questions-title {
    font-size: 20px;
    margin: 1rem 0 0 0;
  }

  .warning-message {
    color: #dc3545;
  }
}