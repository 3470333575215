.intrvws-table-ctn {
  padding: 0 15px;

  th {
    .helper-ctn {
      position: relative;
      top: 4px;
      text-align: start;
    }
  }

  td {
    vertical-align: middle;

    .badge {
      font-size: 14px;
    }
  }

  .toolbox-cell {
    font-size: 24px;
    line-height: 1em;
    text-align: center;

    a {
      color: inherit;
    }

    svg {
      margin-right: 10px;
      position: relative;
      top: -1px;
      cursor: pointer;

      &:hover {
        color: #0d6efd;
      }
    }
  }
}