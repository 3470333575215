.question-row {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 16px;
  position: relative;

  .accordion-item {
    flex-grow: 1;
  }
}

