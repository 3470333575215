$width: 240px;

.sign-up-ctn {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .logo {
    width: $width;
    margin-bottom: 40px;
  }

  hr {
    margin: 0;
    width: $width;
  }

  .google-btn {
    width: $width;
    color: #002956;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;

    svg {
      margin-right: 15px;
    }
  }

  .login-form {
    width: $width;

    .signup-link {
      display: flex;
      font-size: 14px;
      justify-content: center;

      span {
        margin-right: 10px;
      }
    }

    .login-btn {
      width: $width;
      //color: #002956;
    }
  }
}