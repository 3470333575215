.notification-ctn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999999999;
  cursor: pointer;

  .notification-content {
    color: #eee;
  }
}