.loading-ctn {
  $color: #dee2e6;

  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #212529dd;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999999;

  &.fullscreen {
    height: 100vh;
    width: 100vw;
    position: fixed;
  }

  .spinner-border {
    border-color: $color;
    border-right-color: transparent;
  }

  .text {
    margin-left: 15px;
    color: $color;
    font-size: 20px;
  }
}