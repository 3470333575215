.TypeaheadInput {

  .rbt-token {
    background-color: #0450a0;
    color: #e7f4ff;
  }

  input {
    &:focus {
      border: none;
    }
  }
}