.CreateQuestion {
  display: flex;
  flex-direction: column;
  min-height: 400px;
  width: 100%;
  margin-bottom: 15px;

  h1 {
    font-size: 22px;
  }

  .create-area {
    flex-basis: 420px;
    flex-grow: 1;

    .submit-btn {
      position: relative;
    }
  }

  .question-ctn {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;

    .question-input {
      flex-grow: 1;
    }
  }

  .preview-area {
    flex-basis: 420px;
    flex-grow: 1;
    margin-left: 40px;
  }

  .number-ref-row {
    align-items: start;
  }

  .buttons-group {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
  }

  .condition-select {
    width: 150px;
  }

  svg {
    position: relative;
  }
}