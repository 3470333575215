.input-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 400px;
  margin-bottom: 16px;

  .btn {
    &.switch-off {
      padding-left: 20px;
    }
  }
}

.button-outline {
  width: 38px;
  height: 38px;
}

