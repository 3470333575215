.SelectInput {
  option {

    &:hover {
      box-shadow: 0 0 10px 100px #ff00ff inset;
    }
    &:checked {
      //background-color: #0a53be;
    }
  }

  .form-select {
    &:focus {
      box-shadow: none;
      outline: 3px solid #0a53be;
      border-color: #0a53be;
    }
  }
}