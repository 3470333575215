.question-tile-ctn {
  //border: 1px solid #ccc;
  //border-radius: 8px;
  //padding: 25px;
  flex-grow: 1;

  .flex-row {
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  .preview-ctn {
    hr {
      margin: 20px 0;
    }
  }
}